.submit-button-enter {
  opacity: 0;
  transform: scale(0.9);
}

.submit-button-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}

.submit-button-exit {
  opacity: 1;
}

.submit-button-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}

.submit-button-slide {
  position: relative;
}

.submit-button-slide-enter {
  position: relative;
  right: -200px;
}

.submit-button-slide-enter-active {
  position: relative;
  transition: 800ms;
  right: 0;
}

.submit-button-slide-exit {
  position: relative;
  right: 0;
}

.submit-button-slide-exit-active {
  display: none;
}

.submit-button {
  @apply hidden;
  @screen md {
    @apply bg-white text-black text-base self-center shadow-xl my-3 h-12 col-start-3 col-span-2 flex;
  }
}

.white-card-submit-button {
  @apply bg-white text-black text-base shadow-xl my-3 h-12 self-end;
  @screen md {
    @apply hidden;
  }
}